import { retry } from 'bv-lazy-load';
import { guard } from '../features';

let promise;

export default () => {
  promise = promise || Promise.race([
    // 10 second timeout, and if we get no result from iobb continue without it
    new Promise((resolve) => {
      setTimeout(resolve, 10000);
    }),
    guard('iovation')
      .then((enabled) => {
        if (!enabled) return null;

        return retry(() => import(/* webpackChunkName: "iovation" */'../../iovation/index.js')
          .then((module) => module.default()));
      }).catch(() => null),
  ]);

  return promise;
};
